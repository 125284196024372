import React from "react";
import "./404.css";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {};

function NotFoundPage() {
  return (
    <div className="not_found">
      <div className="mars"></div>
      <img src="https://ncg-intra-fileupload.s3-eu-west-1.amazonaws.com/assets/404/404.svg" className="logo-404" />
      <img src="https://ncg-intra-fileupload.s3-eu-west-1.amazonaws.com/assets/404/meteor.svg" className="meteor" />
      <p className="title">Oh no!!</p>
      <p className="subtitle">
        You’re either misspelling the URL <br /> or requesting a page that's no longer here.
      </p>
      <div align="center">
        <a className="btn-back" href="/">
          Back to homepage
        </a>
      </div>
      <img
        src="https://ncg-intra-fileupload.s3-eu-west-1.amazonaws.com/assets/404/astronaut.svg"
        className="astronaut"
      />
      <img
        src="https://ncg-intra-fileupload.s3-eu-west-1.amazonaws.com/assets/404/spaceship.svg"
        className="spaceship"
      />
    </div>
  );
}

export default withStyles(styles)(NotFoundPage);
