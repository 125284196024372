import React, { useState } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@material-ui/lab";
import OpportunityIcon from "@material-ui/icons/Description";
import NewsIcon from "@material-ui/icons/Forum";
import { useHistory } from "react-router-dom";

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      height: 380
    },
    speedDial: {
      position: "fixed",
      bottom: spacing(4),
      right: spacing(4)
    },
    container: {
      minHeight: 100
    },
    primary: {
      color: palette.common.white,
      background: palette.primary.main,
      "&:hover": {
        background: palette.primary.light
      }
    },
    secondary: {
      color: palette.common.white,
      background: palette.secondary.main,
      "&:hover": {
        background: palette.secondary.light
      }
    }
  });

const actions = [
  { icon: <OpportunityIcon />, name: "Opportunity", link: "/opportunities/create", color: "primary" },
  { icon: <NewsIcon />, name: "News", link: "/news/create/", color: "secondary" }
];

const FloatingActionButtons = props => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClick = link => {
    history.push(link);
  };

  const { classes } = props;

  return (
    <div className={classes.container}>
      <SpeedDial
        ariaLabel="Menu button"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClick={() => setOpen(!open)}
        open={open}
      >
        {actions.map(action => (
          <SpeedDialAction
            title={action.name}
            className={classes[action.color]}
            key={action.name}
            icon={action.icon}
            tooltipTitle={"Add " + action.name}
            onClick={() => handleClick(action.link)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default withStyles(styles)(FloatingActionButtons);
