import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer/Drawer";
import { getInitials } from "../../util/functions";
import styles from "./NavigationStyles";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      left: false
    };
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <div className={classes.row}>
          <Avatar className={classes.avatar}>
            {this.props.user ? getInitials(this.props.user.first_name + " " + this.props.user.last_name) : "?"}
          </Avatar>
          <Typography variant={"subtitle1"} className={classes.profile}>
            {this.props.user ? this.props.user.first_name + " " + this.props.user.last_name : "Unknown"}
          </Typography>
        </div>

        <List>
          <Link to={"/"}>
            <ListItem button divider>
              <Typography variant={"body1"}>Home</Typography>
            </ListItem>
          </Link>

          <Link to={"/opportunities"}>
            <ListItem button divider>
              <Typography variant={"body1"}>Opportunities</Typography>
            </ListItem>
          </Link>

          <Link to={"/news"}>
            <ListItem button divider>
              <Typography variant={"body1"}>News</Typography>
            </ListItem>
          </Link>

          <Link to={"/profile"}>
            <ListItem button divider>
              <Typography variant={"body1"}>Profile</Typography>
            </ListItem>
          </Link>
        </List>
      </div>
    );

    return (
      <div>
        <IconButton
          onClick={this.toggleDrawer("left", true)}
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant={"temporary"}
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
          onOpen={this.toggleDrawer("left", true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(Navigation));
