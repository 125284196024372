import React from "react";
import Axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import { createUser, deleteUser, getUsers, updateUser } from "../Axios";
import EditUser from "../components/EditUser";
import UserTable from "../components/userTable";
import CreateUser from "../components/createUser/CreateUser";
Axios.defaults.withCredentials = true;

const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    margin: theme.spacing(2)
  },
  table: {
    minWidth: 500
  },
  tableWrapper: {
    overflowX: "auto"
  },
  row: {
    display: "contents",
    "&:hover": {
      background: theme.palette.primary.main
    }
  },
  flex: {
    display: "flex"
  },
  title: {
    alignSelf: "center",
    display: "initial",
    marginRight: 10
  },
  header: {
    flex: 1,
    paddingTop: theme.spacing(2)
  },
  label: {
    marginBottom: 0
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginRight: "auto",
    marginLeft: "auto"
  }
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: null,
      page: 0,
      size: 9,
      loaded: false,
      open: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClick(id) {
    if (id) {
      this.setState({
        user: this.state.users.find(user => {
          return user._id === id;
        })
      });
    }
    this.setState({
      open: !this.state.open
    });
  }

  handleDelete(userId) {
    deleteUser(userId, () => {
      this.setState({ users: [...this.state.users].filter(user => user._id !== userId), loaded: true });
    });
  }

  handleCreate(user) {
    this.setState({
      users: [...this.state.users, user]
    });
  }

  handleUpdate(user) {
    this.setState({
      users: this.state.users.map(u => (u._id === user._id ? user : u))
    });
  }

  handleChange(e) {
    // check it out: we get the evt.target.name
    // and use it to target the key on our `state` object with the same name, using bracket syntax
    this.setState({ user: { ...this.state.user, [e.target.name]: e.target.value } });
  }

  componentDidMount() {
    getUsers(users => {
      this.setState({
        users: users,
        loaded: true
      });
    });
  }

  render() {
    const { users, loaded, open, user } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12 opportunity-container">
            <UserTable
              users={users}
              loaded={loaded}
              handleCreate={this.handleCreate}
              handleClick={this.handleClick}
              handleDelete={this.handleDelete}
              handleUpdate={this.handleUpdate}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
