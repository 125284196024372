const fields = [
  { name: "title", type: "text", category: "top" },

  { name: "status", type: "select", multi: false, clearable: false, category: "details" },
  { name: "stage", type: "select", multi: false, clearable: false, category: "details" },
  { name: "country", type: "select", multi: true, clearable: true, category: "details" },
  { name: "region", type: "select", multi: false, clearable: false, category: "details" },

  { name: "application_deadline", type: "date", category: "details", includeTime: true },

  { name: "project_manager", type: "select", multi: false, clearable: true, category: "details" },
  { name: "partner_anchor", type: "select", multi: false, clearable: true, category: "details" },

  { name: "currency", type: "select", multi: false, clearable: true, category: "details" },
  { name: "max_budget", type: "number", category: "details" },
  { name: "donor", type: "select", multi: false, clearable: true, category: "details" },

  // {name: "contract_value", type: "number", category: "details"}, // TODO needs revision

  { name: "duration", type: "text", category: "details" },
  { name: "found", type: "text", category: "details" },

  { name: "contract_description", type: "editor", category: "textarea" },
  { name: "selection_criteria", type: "editor", category: "textarea" },
  { name: "types_of_services", type: "editor", category: "textarea" }
];

export default fields;
