import Axios from "axios";
import { api } from "./util/constants";
import store from "./redux/store";
import { setUser, clearUser, addMessage } from "./redux/actions";
Axios.defaults.withCredentials = true;

/**
 * Comments
 * @param type
 * @param id
 * @param payload
 * @param callback
 */

export function addComment(type, id, payload, callback) {
  Axios.post(api.url + "/" + type + "/" + id + "/comments", payload)
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

/**
 * Search
 */

export function searchOpportunities(params, callback) {
  Axios.post(api.url + "/search/opportunities/" + params, { data: params })
    .then(resp => callback(resp.data))
    .catch(error => handleError(error));
}

export function searchNews(params, callback) {
  Axios.post(api.url + "/search/news/" + params, { data: params })
    .then(resp => callback(resp.data))
    .catch(error => handleError(error));
}

/**
 * OPPORTUNITIES
 */

export function createOpportunity(opportunity) {
  return new Promise((resolve, reject) => {
    Axios.post(api.url + "/opportunities", { opportunity })
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function getOpportunities(callback) {
  Axios.get(api.url + "/opportunities")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getOpportunity(id, callback) {
  Axios.get(api.url + "/opportunities/" + id)
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getFilters(callback) {
  Axios.get(api.url + "/opportunities/filters")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function updateOpportunity(opportunity) {
  return new Promise((resolve, reject) => {
    Axios.put(api.url + "/opportunities/" + opportunity._id, { opportunity })
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function deleteOpportunity(id, callback) {
  Axios.delete(api.url + "/opportunities/" + id)
    .then(resp => callback && callback(resp.status))
    .catch(error => handleError(error));
}

/**
 * NEWS
 */

export function createNews(news) {
  return new Promise((resolve, reject) => {
    Axios.post(api.url + "/news/", { news })
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function getNews(callback) {
  Axios.get(api.url + "/news")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getSingleNews(id) {
  return new Promise((resolve, reject) => {
    Axios.get(api.url + "/news/" + id)
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function updateSingleNews(news) {
  return new Promise((resolve, reject) => {
    Axios.put(api.url + "/news/" + news._id, { news })
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function deleteNews(id, callback) {
  Axios.delete(api.url + "/news/" + id, { data: id })
    .then(resp => callback && callback(resp.status))
    .catch(error => handleError(error));
}

/**
 * Users
 */

export function createUser(user, callback) {
  Axios.post(api.url + "/users", { user })
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function updateUser(user, callback) {
  Axios.put(api.url + "/users/" + user._id, { user })
    .then(resp => callback && callback())
    .catch(error => handleError(error));
}

export function deleteUser(userId, callback) {
  Axios.delete(api.url + "/users/" + userId)
    .then(resp => callback())
    .catch(error => handleError(error));
}

export function getUsers(callback) {
  Axios.get(api.url + "/users")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getCurrentUser(callback) {
  Axios.get(api.url + "/users/me")
    .then(resp => callback && callback(resp.data))
    .catch(error => callback && callback(error));
}

export function login(user) {
  return new Promise((resolve, reject) => {
    Axios.post(api.url + "/users/login", { email: user.email, password: user.password })
      .then(resp => {
        store.dispatch(setUser(resp.data.user));
        resolve(resp.data.user);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function logout() {
  document.cookie = "ncgtok= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  window.location.replace("/login");
}

function handleError(error) {
  if (!error.response) {
    // network error
    // console.log("Error: ", "Network error")
    //window.location.replace("/not-found")
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 503) {
      window.location.replace("/not-found");
    }
    if (error.response.status === 404) {
      window.location.replace("/not-found");
    } else if (error.response.status === 403) {
      document.cookie = "ncgtok= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      console.log(window.location.pathname);
      store.dispatch(clearUser());
      // if (window.location.pathname.length) {
      //   window.location.replace("/login?redirect=" + window.location.pathname);
      // } else {
      //   window.location.replace("/login");
      // }
    } else {
      if (error.response.data.message) store.dispatch(addMessage(error.response.data.message, "ERROR"));
      else store.dispatch(addMessage(error.response.data[Object.keys(error.response.data)[0]].message, "ERROR"));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    //window.location.replace("/not-found")
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
    console.log("Error: ", error.config);

    //window.location.replace("/not-found")
  }
  //window.location.replace("/not-found")
}
