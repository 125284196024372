import React from "react";
import AddComment from "./AddComment";
import PropTypes from "prop-types";
import {addComment} from "../../Axios";
import TimeAgo from "react-timeago";
import styles from "./CommentStyles";
import {getInitials} from "../../util/functions";
import {connect} from "react-redux";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Card,
    CardContent,
    Typography,
    withStyles
} from "@material-ui/core";

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            show: !this.state.show
        });
    }

    render() {
        const {classes, comment} = this.props;

        return (
            <ListItem alignItems="flex-start" className={classes.leftAlignment}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>{getInitials(comment.author)}</Avatar>
                </ListItemAvatar>
                <ListItemText>
                    <Card className={classes.comment} raised={false}>
                        <CardContent className={classes.cardContent}>
              <span>
                <Typography className={classes.date} color="textSecondary">
                  {
                      <TimeAgo
                          date={comment.date}
                          formatter={(value, unit, suffix) => {
                              if (unit === "second") {
                                  return "just now";
                              } else {
                                  return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
                              }
                          }}
                      />
                  }
                </Typography>
                <Typography className={classes.commentTitle} gutterBottom variant="subtitle1" component="h2">
                  {comment.author}
                </Typography>
              </span>
                            {/*<Button onClick={this.handleClick} color="primary" style={{padding: 0, minHeight: 0 }} className={classes.date}>*/}
                            {/*    Reply*/}
                            {/*</Button>*/}
                            <Typography className={classes.commentBody} variant={"subtitle2"} component="p">
                                {comment.body}
                            </Typography>
                        </CardContent>
                    </Card>

                    {/*{comment.subcomments ? (*/}
                    {/*    <div>*/}
                    {/*        <List>*/}
                    {/*            {comment.subcomments.map((subcomment, index) =>*/}
                    {/*                <div key={subcomment._id}>*/}
                    {/*                    <ListItem key={subcomment._id}*/}
                    {/*                              alignItems="flex-start"*/}
                    {/*                              className={classes.rightAlignment}>*/}
                    {/*                        <ListItemAvatar*/}
                    {/*                            className={classes.avatar}>*/}
                    {/*                            <Avatar>*/}
                    {/*                                {getInitials(subcomment.author)}*/}
                    {/*                            </Avatar>*/}
                    {/*                        </ListItemAvatar>*/}
                    {/*                        <ListItemText*/}
                    {/*                            className={classes.rightAlignment}>*/}
                    {/*                            <Card*/}
                    {/*                                className={classes.subcomment}*/}
                    {/*                                raised={false}>*/}
                    {/*                                <CardContent*/}
                    {/*                                    className={classes.cardContent}>*/}
                    {/*                                    <Typography*/}
                    {/*                                        className={classes.date}*/}
                    {/*                                        color="textSecondary">*/}
                    {/*                                        {getDate(subcomment.date)}*/}
                    {/*                                    </Typography>*/}
                    {/*                                    <Typography*/}
                    {/*                                        className={classes.subcomment}*/}
                    {/*                                        gutterBottom*/}
                    {/*                                        variant="subtitle1"*/}
                    {/*                                        component="h2">*/}
                    {/*                                        {subcomment.author}*/}
                    {/*                                    </Typography>*/}
                    {/*                                    <Typography*/}
                    {/*                                        className={classes.commentBody}*/}
                    {/*                                        variant={"subtitle2"}*/}
                    {/*                                        component="p">*/}
                    {/*                                        {subcomment.body}*/}
                    {/*                                    </Typography>*/}
                    {/*                                </CardContent>*/}
                    {/*                            </Card>*/}
                    {/*                        </ListItemText>*/}
                    {/*                    </ListItem>*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*            {this.state.show &&*/}
                    {/*            <AddComment*/}
                    {/*                name={user.firstname + " " + user.lastname}*/}
                    {/*                author={comment.author}*/}
                    {/*                handleComment={handleComment}*/}
                    {/*                id={comment._id}*/}
                    {/*                variant={"reply"}*/}
                    {/*            />*/}
                    {/*            }*/}
                    {/*        </List>*/}

                    {/*    </div>*/}
                    {/*) : null}*/}
                </ListItemText>
            </ListItem>
        );
    }
}

Comment.propTypes = {
    id: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    handleComment: PropTypes.func.isRequired
};

Comment = connect(mapStateToProps)(withStyles(styles)(Comment));

class Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleComment = (payload, callback) => {
        addComment(this.props.type, this.props.id, payload, (opportunity) => {
            this.props.handleUpdate(opportunity);
            callback(true);
        });
    };

    render() {
        const {handleComment} = this;
        const {comments, id} = this.props;

        return (
            <div>
                <List>
                    {comments.map((comment, index) => (
                        <Comment key={index} id={id} handleComment={handleComment} comment={comment}/>
                    ))}
                </List>
                <AddComment handleComment={handleComment} variant={"comment"}/>
            </div>
        );
    }
}

Comments.propTypes = {
    handleUpdate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(Comments);
