import React, { useState, FunctionComponent, useEffect } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { capitalize } from "../util/functions";

const styles = theme => ({
  container: {
    flexBasis: "100%",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link"
];

type Props = {
  field: string;
  handleChange: Function;
  [x: string]: any;
};

const Editor: FunctionComponent<Props> = ({ field, handleChange, ...props }) => {
  const [html, setHtml] = useState(props.value || "");

  useEffect(() => {
    handleChange(field, html);
  }, [html]);

  return (
    <div className={props.classes.container}>
      <div>
        <Typography variant={"h6"} gutterBottom={true}>
          {capitalize(field.replace(/_/g, " "))}
        </Typography>
      </div>
      <ReactQuill
        onChange={value => {
          setHtml(value);
          handleChange(field, value);
        }}
        value={html}
        modules={modules}
        formats={formats}
        placeholder={"Write something..."}
      />
    </div>
  );
};

export default withStyles(styles)(Editor);
