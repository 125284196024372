const styles = theme => ({
    list: {
        width: 250,
    },
    listItem: {

    },
    fullList: {
        width: 'auto',
    },
    row: {
        display: 'grid',
        background: theme.palette.primary.main,
        padding: '30px 20px 30px 40px'
    },
    avatar: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main
    },
    bigAvatar: {
        width: 60,
        height: 60,
    },
    profile: {
        marginTop: 10,
        color: theme.palette.common.white,
    }
});

export default styles