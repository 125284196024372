const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  header: {
    paddingTop: theme.spacing(2)
  },
  title: {
    alignSelf: "center"
  },
  inProgress: {
    color: theme.palette.primary.main
  },
  newOpportunities: {
    color: theme.palette.tertiary.main
  },
  news: {
    color: theme.palette.secondary.main
  }
});

export default styles;
