import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles/index";
import Card from "@material-ui/core/Card/index";
import CardActionArea from "@material-ui/core/CardActionArea/index";
import CardContent from "@material-ui/core/CardContent/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import Avatar from "@material-ui/core/Avatar/index";
import Typography from "@material-ui/core/Typography/index";
import ForumIcon from "@material-ui/icons/Forum";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge/Badge";
import ModeComment from "@material-ui/icons/ModeComment";
import { commentCounter, getDate, getExcerpt } from "../../util/functions";

const styles = theme => ({
  root: {
    background: "#fff"
  },
  card: {
    //background: Indigo[100],
    "& a": {
      color: "inherit",

      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  info: {
    paddingLeft: 10
    //color: Indigo[700]
  },
  cardActionArea: {
    width: "100%"
  },
  actionArea: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    background: "inherit"
  },
  newsAvatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  newsHeader: {},
  comments: {
    fontWeight: "700"
  },
  commentIcon: {
    marginRight: 16,
    paddingTop: 16
  },
  badgeRoot: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main
  }
});

function News(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <Link to={"/news/" + props.news._id}>
        <CardActionArea className={classes.cardActionArea}>
          <CardHeader
            action={
              <div className={classes.commentIcon}>
                <Badge classes={{ badge: classes.badgeRoot }} badgeContent={commentCounter(props.news.comments)}>
                  <ModeComment color="secondary" />
                </Badge>
              </div>
            }
            avatar={
              <Avatar className={classes.newsAvatar}>
                <ForumIcon />
              </Avatar>
            }
            title={getExcerpt(props.news.title, 90)}
            subheader={getDate(props.news.created_date)}
            className={classes.newsHeader}
          />

          <CardContent>
            <Typography variant={"body2"} className={classes.info} component="p">
              {getExcerpt(props.news.body, 150)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

News.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(News);
