import Blue from "@material-ui/core/colors/blue";
import Pink from "@material-ui/core/colors/pink";
import Red from "@material-ui/core/colors/red";
import Indigo from "@material-ui/core/colors/indigo";

import { createStyles, Theme } from "@material-ui/core";

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      paddingTop: spacing(2)
    },
    paper: {
      padding: spacing(4)
    },
    headerContainer: {
      display: "flex",
      alignItems: "center"
    },
    alignLeft: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center"
    },
    alignRight: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      lineHeight: 1.5,
      //textTransform: 'uppercase'
      display: "initial"
    },
    row: {
      paddingTop: spacing(3)
    },
    infoRow: {
      display: "flex",
      flexWrap: "wrap",
      // justifyContent: "space-between",
      paddingTop: spacing(3),
      paddingBottom: spacing(3)
    },
    itemRow: {
      paddingTop: spacing(),
      paddingBottom: spacing(),
      display: "flex",
      alignItems: "baseline"
    },
    item: {
      // flexGrow: 1,
      flexBasis: 300,
      marginBottom: spacing(2)
      // maxWidth: '25%'
    },
    floatRight: {
      float: "right"
    },
    avatar: {
      marginTop: 10,
      background: Blue[700]
    },
    comment: {},
    subcomment: {},
    subaligment: {
      width: "100%",
      padding: " 0 16px"
    },
    rightAlignment: {
      // paddingRight: 0
    },
    leftAlignment: {
      // paddingLeft: 0
    },
    replybutton: {},
    iconbutton: {
      transform: "rotate(-45deg)"
    },
    commentTitle: {
      fontWeight: "bold",
      color: Blue[700]
    },
    commentBody: {
      whiteSpace: "pre-wrap",
      lineHeight: 1.5
    },
    date: {
      float: "right"
    },
    commentFlex: {
      display: "flex"
    },
    commentBox: {
      margin: 0,
      flexGrow: 1,
      alignItems: "flex-start"
    },
    cardContent: {
      padding: 12,
      "&:last-child": {
        paddingBottom: 14
      }
    },
    buttonProgress: {
      color: Pink[500],
      position: "absolute",
      top: "25%",
      left: "25%",
      marginTop: -14,
      marginLeft: -14
    },
    error: {
      color: Red[500]
    },
    file: {
      color: palette.primary.main,
      textDecoration: "underline",
      "&:hover": {
        color: palette.primary.light,
        textDecoration: "underline"
      }
    },
    chip: {
      marginRight: spacing()
    },
    chipLink: {
      marginRight: spacing(),
      cursor: "pointer",
      background: Indigo[700]
    }
  });

export default styles;
