export const api = {
  url: process.env["MODE"] === "DEVELOPMENT" ? "http://localhost:3030" : "https://ncgbackend.ncg.dk",
  s3: "https://ncg-intra-fileupload.s3-eu-west-1.amazonaws.com/"
};

export const projectDatabaseUrl =
  process.env["MODE"] === "DEVELOPMENT" ? "http://localhost:8080" : "https://project.ncg.dk";

export const stages = [
  {
    value: "EOI",
    label: "EOI"
  },
  {
    value: "Proposal",
    label: "Proposal"
  },
  {
    value: "Submitted",
    label: "Submitted"
  },
  {
    value: "Dropped",
    label: "Dropped"
  },
  {
    value: "Won",
    label: "Won"
  }
];

export const status = [
  {
    value: "New Opportunity",
    label: "New Opportunity"
  },
  {
    value: "In Progress",
    label: "In Progress"
  }
];

export const currencies = [
  {
    value: "DKK",
    label: "DKK"
  },
  {
    value: "SEK",
    label: "SEK"
  },
  {
    value: "NOK",
    label: "NOK"
  },
  {
    value: "EUR",
    label: "EUR"
  },
  {
    value: "USD",
    label: "USD"
  },
  {
    value: "GBP",
    label: "GBP"
  }
];
