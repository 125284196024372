import { createStore } from "redux";
import reducers from "./reducers";

const initialState = localStorage["intra-store"] ? JSON.parse(localStorage["intra-store"]) : {};

const saveState = () => (localStorage["intra-store"] = JSON.stringify(store.getState()));

const store = createStore(reducers, initialState);

store.subscribe(saveState);

export default store;
