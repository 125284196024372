import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Paper,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Chip
} from "@material-ui/core";
import TimeAgo from "react-timeago";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { deleteNews, getSingleNews, updateSingleNews } from "../../../Axios";
import styles from "./SingleNewsStyles";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import Comments from "../../../components/comments/Comments";
import { connect } from "react-redux";
import { api } from "../../../util/constants";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const SingleNews = props => {
  const [news, setNews] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getSingleNews(id)
      .then(news => {
        setNews(news);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id = null) => {
    if (id) {
      deleteNews(id, status => {
        if (status === 200) {
          history.push("/");
        }
      });
    } else {
      setDeleteModal(false);
    }
  };

  const handleUpdate = news => {
    setNews(news);
    setAnchorEl(null);
    setLoading(false);
    setDeleteModal(false);
  };

  const handleArchive = () => {
    setAnchorEl(null);
    setLoading(true);
    updateSingleNews({ ...news, archived: !news.archived })
      .then(news => {
        setNews(news);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const { classes, user } = props;
  const open = Boolean(anchorEl);

  return (
    <div>
      <Loader loading={loading}>
        {!!news && (
          <div>
            <div className={classes.root}>
              <Paper className={classes.paper} elevation={10}>
                <div>
                  <div className={classes.headerContainer}>
                    <div className={classes.alignLeft}>
                      <Chip
                        className={classes.chip}
                        color={!news.archived ? "secondary" : "default"}
                        label={!news.archived ? "Active" : "Archived"}
                      />
                    </div>
                    <div className={classes.alignRight}>
                      <Typography className={classes.floatRight} variant={"body2"}>
                        <div>
                          Added by {news.author}{" "}
                          <TimeAgo
                            date={news.created_date}
                            formatter={(value, unit, suffix) => {
                              if (unit === "second") {
                                return "just now";
                              } else {
                                return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
                              }
                            }}
                          />
                        </div>
                      </Typography>
                      <IconButton
                        className={classes.floatRight}
                        aria-label="More"
                        aria-owns={open ? "long-Navigation" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: 200,
                            right: 30,
                            left: 0
                          }
                        }}
                      >
                        <MenuItem onClick={() => history.push("/news/edit/" + news._id)}>Edit</MenuItem>

                        <MenuItem onClick={() => setDeleteModal(true)}>Delete</MenuItem>
                        {user.type === 1 && (
                          <MenuItem onClick={() => handleArchive()}>{news.archived ? "Unarchive" : "Archive"}</MenuItem>
                        )}
                      </Menu>
                    </div>
                  </div>
                  <div className={classes.row}>
                    <Typography variant="h5" className={classes.title} component="h3">
                      {news.title}
                    </Typography>
                  </div>
                  <Divider />
                  <div className={classes.row}>
                    <Typography variant={"body1"}>
                      <div dangerouslySetInnerHTML={{ __html: news.body }} />
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography variant={"h6"}>Related files:</Typography>
                    {news.files.map((file, index) => (
                      <div className={classes.item}>
                        <a
                          className={classes.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          href={api.url + "/uploads/" + news._id + "?load=" + encodeURIComponent(file)}
                          download
                        >
                          {file}
                        </a>
                      </div>
                    ))}
                  </div>

                  {/**
                                     *
                                     Comments
                                     *
                                     **/}
                  <Comments handleUpdate={handleUpdate} comments={news.comments} id={news._id} type={"news"} />
                </div>
              </Paper>
            </div>

            <DeleteDialogue open={deleteModal} news={news} handleClick={handleDelete} />
          </div> // end of inner div
        )}
      </Loader>
    </div> //end of outer div
  );
};

SingleNews.propTypes = {
  classes: PropTypes.object.isRequired
};

const DeleteDialogue = ({ open, news, handleClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deleting {news.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you wish to delete this post?
          <br />
          <br />
          Deleting a post is permanent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClick(news._id)} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(SingleNews));
