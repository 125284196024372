import React, { useState, FunctionComponent } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { commentCounter, getDateAndTime } from "../util/functions";
import Loader from "./Loader";
import {
  Switch,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  Paper,
  Typography,
  Toolbar,
  FormControlLabel,
  Table,
  TableBody,
  TablePagination,
  withStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import INews from "../interfaces/INews";

function desc(a, b, orderBy) {
  if (orderBy === "comments") {
    if (commentCounter(b[orderBy]) < commentCounter(a[orderBy])) {
      return -1;
    }
    if (commentCounter(b[orderBy]) > commentCounter(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "title", numeric: false, disablePadding: false, label: "Title" },
  { id: "author", numeric: true, disablePadding: false, label: "Author" },
  { id: "comments", numeric: true, disablePadding: false, label: "Comments" },
  { id: "created_date", numeric: true, disablePadding: false, label: "Created Date" },
  { id: "last_active", numeric: true, disablePadding: false, label: "Last Active" }
];

const EnhancedTableHead = props => {
  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };

  const { order, orderBy, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => {
          return (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto"
    },
    spacer: {
      flex: "1 1 100%"
    },
    actions: {
      color: palette.text.secondary,
      flex: "none"
    },
    title: {
      flex: "0 0 auto"
    },
    tableRow: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  });

type Props = {
  classes: any;
  news: INews[];
  loading: boolean;
  search: string;
};

const NewsTable: FunctionComponent<Props> = props => {
  const [order, setOrder] = useState<string>("desc");
  const [orderBy, setOrderBy] = useState<string>("created_date");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const history = useHistory();
  const [showArchived, setShowArchived] = useState(false);

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (orderBy === property && order === "desc") {
      order = "asc";
    }
    setOrder(order);
    setOrderBy(orderBy);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const handleFilter = () => {
    setShowArchived(!showArchived);
  };

  const handleClick = to => {
    history.push(to);
  };

  const { classes, loading, news, search } = props;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, news.length - page * rowsPerPage);

  let shownNews;

  showArchived
    ? (shownNews = news.filter(news => news.archived === true))
    : (shownNews = news.filter(news => news.archived === false));

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="h6">News {search && "Search: " + search}</Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <FormControlLabel
            control={<Switch checked={showArchived} onChange={handleFilter} color="secondary" />}
            label={"Show Archived"}
          />
        </div>
      </Toolbar>
      <div className={classes.tableWrapper}>
        <Loader loading={loading}>
          {news.length ? (
            <Table className={classes.table}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={news.length}
              />
              <TableBody>
                {stableSort(shownNews, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(news => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        onClick={() => handleClick("/news/" + news._id)}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={news._id}
                      >
                        <TableCell component="th" scope="row">
                          {news.title}
                        </TableCell>
                        <TableCell align="right">{news.author}</TableCell>
                        <TableCell align="right">{commentCounter(news.comments)}</TableCell>
                        <TableCell align="right">{getDateAndTime(news.created_date)}</TableCell>
                        <TableCell align="right">{getDateAndTime(news.last_active)}</TableCell>
                      </TableRow>
                    );
                  })}

                {/*{emptyRows > 0 && (*/}
                {/*<TableRow style={{ height: 49 * emptyRows }}>*/}
                {/*<TableCell colSpan={6} />*/}
                {/*</TableRow>*/}
                {/*)}*/}
              </TableBody>
            </Table>
          ) : (
            <Toolbar>
              <div className={classes.title}>
                <Typography variant="body1">No News Found</Typography>
              </div>
            </Toolbar>
          )}
        </Loader>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={news.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

NewsTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewsTable);
