

export const profileStyles = (theme) => ({
  container: {
    width: "auto",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  profile: {
    marginTop: theme.spacing(2)
  },
  header: {
    flexGrow: 1,
    background: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "10em"
  },
  avatar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    height: 60,
    width: 60
  },
  title: {
    padding: theme.spacing(),
    color: theme.palette.common.white
  },
  content: {
    padding: theme.spacing(2)
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexBasis: 250,
    flexGrow: 1
  },
  warning: {
    color: theme.palette.secondary.main
  }
});


