import React from "react";
import {
    List,
    Avatar,
    ListItemAvatar,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    CardActions,
    Fab,
    Tooltip,
    InputBase,
    CircularProgress,
    FormControl,
    withStyles
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import SendIcon from "@material-ui/icons/Send";

import {getInitials} from "../../util/functions";
import styles from "./CommentStyles";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

class AddComment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: "",
            loading: false,
            error: false
        };
    }

    handleChange = (prop) => (event) => {
        this.setState({error: false});
        this.setState({[prop]: event.target.value});
    };

    handleClick = () => {
        if (this.state.body) {
            this.setState({loading: true});

            const payload = {
                comment: {body: this.state.body},
                ...(this.props.id && {id: this.props.id})
            };

            this.props.handleComment(payload, (saved) => {
                if (saved) {
                    this.setState({
                        body: "",
                        loading: false
                    });
                }
            });
        }
    };

    render() {
        const {classes, user} = this.props;

        return (
            <List>
                <ListItem
                    className={this.props.variant === "reply" ? classes.rightAlignment : classes.leftAlignment}
                    alignItems="flex-start"
                >
                    <ListItemAvatar>
                        <Avatar /*className={classes.avatar}*/>{getInitials(user.first_name + " " + user.last_name)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText className={this.props.variant === "reply" ? classes.rightAlignment : null}>
                        <Card raised={true}>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.commentFlex}>
                                    <FormControl
                                        classes={{
                                            root: classes.commentBox
                                        }}
                                    >
                                        <InputBase
                                            placeholder={
                                                this.props.variant === "reply"
                                                    ? "Reply to " + this.props.author + "'s comment?"
                                                    : "Comment on this post?"
                                            }
                                            classes={{
                                                root: classes.commentBox
                                            }}
                                            value={this.state.body}
                                            onChange={this.handleChange("body")}
                                            multiline={true}
                                            fullWidth={true}
                                            rows={2}
                                            rowsMax={99}
                                            margin="none"
                                            variant="outlined"
                                            maxLength={6}
                                        />
                                    </FormControl>

                                    {this.props.variant === "comment" ? (
                                        <CardActions>
                                            <Tooltip title="Comment" aria-label="Comment" placement={"top"}>
                                                <Fab
                                                    disabled={this.state.loading}
                                                    color="primary"
                                                    className={classes.iconbutton}
                                                    onClick={this.handleClick}
                                                >
                                                    <SendIcon/>
                                                </Fab>
                                            </Tooltip>
                                        </CardActions>
                                    ) : null}
                                    {this.props.variant === "reply" ? (
                                        <CardActions>
                                            <Tooltip title="Reply" aria-label="Reply" placement={"top"}>
                                                <Fab disabled={this.state.loading} color="primary"
                                                     onClick={this.handleClick}>
                                                    <ReplyIcon/>
                                                    {this.state.loading ? (
                                                        <CircularProgress size={56} className={classes.buttonProgress}/>
                                                    ) : null}
                                                </Fab>
                                            </Tooltip>
                                        </CardActions>
                                    ) : null}
                                </div>
                                {/** END OF CommentFlex **/}
                            </CardContent>
                        </Card>
                    </ListItemText>
                </ListItem>
            </List>
        );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AddComment));
