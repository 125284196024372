import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  withStyles
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { FunctionComponent, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IUser from "../../interfaces/IUser";

type Props = {
  classes: any;
  user: IUser;
  handleDelete: Function;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteUser: FunctionComponent<Props> = props => {
  const { classes, user, handleDelete } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <IconButton onClick={() => setOpen(true)} aria-label="delete">
        <DeleteIcon fontSize="default" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-slide-title">{"Delete User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete {user.first_name} {user.last_name}
            <br />
            <br />
            Deleting a user is permanent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(user._id);
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteUser;
