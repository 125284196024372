import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {getNews} from "../../Axios";
import NewsTable from "../../components/NewsTable";
import FAButton from "../../components/fabButton/FAButton";


const styles = theme => ({
    root: {
        width: '100%',
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    table: {
        minWidth: 500,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        display: 'contents',
        '&:hover': {
            background: theme.palette.primary.main
        }
    },
    flex: {
        display: 'flex'
    },
    title: {
        alignSelf: 'center'
    },
    header: {
        flex: 1,
        paddingTop: theme.spacing(2)
    },

});


class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            page: 0,
            newsPerPage: 10,
            list: true,
            table: null,
            loaded: false,
            test: ''
        }

        this.handleSwitch = this.handleSwitch.bind(this)
    }


    handleSwitch() {
        this.setState({
            list: !this.state.list
        })
    }

    componentDidMount() {
        getNews(news => this.setState({news: news, loaded: true}))
    }


    render() {
        const {news, loaded} = this.state;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12 opportunity-container">

                        <NewsTable news={news} loaded={loaded}/>

                    </div>
                </div>
                <FAButton/>
            </div>
        )
    }
};


export default withStyles(styles)(News);
