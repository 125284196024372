import React, { FunctionComponent } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Card from "@material-ui/core/Card/index";
import CardActionArea from "@material-ui/core/CardActionArea/index";
import CardContent from "@material-ui/core/CardContent/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import Avatar from "@material-ui/core/Avatar/index";
import Typography from "@material-ui/core/Typography/index";
import DescriptionIcon from "@material-ui/icons/Description";
import ModeComment from "@material-ui/icons/ModeComment";
import Badge from "@material-ui/core/Badge/index";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import styles from "./OpportunityCardStyles";
import { getProjectNumber } from "../../util/functions";
import IOpportunity from "../../interfaces/IOpportunity";

type Props = {
  classes: any;
  color?: "green" | "blue";
  id: string;
  title: string;
  created_date: Date;
  opportunity: IOpportunity;
  deadline?: Date;
  comments?: number;
  status?: string;
  inProgress?: string;
};

const Opportunity: FunctionComponent<Props> = props => {
  const { classes, opportunity, deadline } = props;

  const application_deadline = Math.round(
    (new Date(opportunity.application_deadline).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  );

  return (
    <Card className={classes.card}>
      <Link to={"/opportunities/" + props.id}>
        <CardActionArea className={classes.cardActionArea}>
          <CardHeader
            avatar={
              <Avatar className={props.color === "blue" ? classes.inProgressAvatar : classes.newOpportunityAvatar}>
                <DescriptionIcon />
              </Avatar>
            }
            title={
              (opportunity?.project?.id
                ? getProjectNumber(
                    opportunity.project.project_number.toString(),
                    opportunity.project.year.toString(),
                    opportunity.project.owner
                  )
                : "") +
              " " +
              opportunity.title
            }
            action={
              <div className={classes.commentIcon}>
                <Badge
                  classes={{
                    badge: props.color === "blue" ? classes.inProgressBadgeRoot : classes.newOpportunityBadgeRoot
                  }}
                  badgeContent={props.comments ? props.comments : "0"}
                >
                  <ModeComment className={props.color === "blue" ? classes.blueIcon : classes.greenIcon} />
                </Badge>
              </div>
            }
            subheader={
              <div>
                Last active{" "}
                <TimeAgo
                  date={opportunity.last_active}
                  formatter={(value, unit, suffix) => {
                    if (unit === "second") {
                      return "just now";
                    } else {
                      return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix + " ";
                    }
                  }}
                />
              </div>
            }
            //subheader={<div>PM: {opportunity.project_manager} <strong>|</strong> PA:  {opportunity.partner_anchor}</div>}
            className={classes.opportunityHeader}
          />
          {props.status && (
            <CardContent>
              <div style={{ float: "left" }}>
                <Typography variant={"body2"} component="p" align="left" className={classes.info}>
                  PM: {props.opportunity.project_manager ? props.opportunity.project_manager : "TBD"} | Partner:{" "}
                  {props.opportunity.partner_anchor ? props.opportunity.partner_anchor : "TBD"}
                </Typography>
              </div>
              <div>
                <Typography variant={"body2"} component="p" align="right" className={classes.info}>
                  {deadline
                    ? "Deadline in " + application_deadline + (application_deadline === 1 ? " day" : " days")
                    : props.status}
                </Typography>
              </div>
            </CardContent>
          )}
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default withStyles(styles)(Opportunity);
