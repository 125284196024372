import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { commentCounter } from "../util/functions";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Loader from "./Loader";
import DeleteUser from "../components/deleteUser/DeleteUser";
import EditUser from "../components/editUser/EditUser";
import CreateUser from "../components/createUser/CreateUser";

function desc(a, b, orderBy) {
  if (orderBy === "comments") {
    if (commentCounter(b[orderBy]) < commentCounter(a[orderBy])) {
      return -1;
    }
    if (commentCounter(b[orderBy]) > commentCounter(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "first_name", numeric: false, disablePadding: false, label: "First Name" },
  { id: "last_name", numeric: true, disablePadding: false, label: "Last Name" },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "subscribed", numeric: true, disablePadding: false, label: "Subscribed" },
  { id: "action", numeric: true, disablePadding: false, label: "" }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  },
  actions: {
    float: "right"
  },
  spacer: {
    flex: "1 1 100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  actions: {
    display: "inline-flex"
  }
});

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "first_name",
      page: 0,
      rowsPerPage: 25
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy });
  };

  handleClick = prop => {};

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes, loaded, users, search, handleClick, handleDelete, handleUpdate, handleCreate } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6">Users {search && "Search: " + search}</Typography>
          </div>
          {!search && (
            <div className={classes.spacer}>
              <div className={classes.actions}>
                <CreateUser handleCreate={handleCreate} />
              </div>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Loader loaded={loaded}>
            {users.length ? (
              <Table className={classes.table}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={users.length}
                />
                <TableBody>
                  {stableSort(users, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(user => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user._id}>
                          <TableCell component="th" scope="row">
                            {user.first_name}
                          </TableCell>
                          <TableCell align="right">{user.last_name}</TableCell>
                          <TableCell align="right">{user.email}</TableCell>
                          <TableCell align="right">{user.subscribed ? "Yes" : "No"}</TableCell>
                          <TableCell align="right">
                            <div className={classes.actions}>
                              <EditUser user={user} handleUpdate={handleUpdate} />
                              <DeleteUser user={user} handleDelete={handleDelete} />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : (
              <Toolbar>
                <div className={classes.title}>
                  <Typography variant="body1">No Users Found</Typography>
                </div>
              </Toolbar>
            )}
          </Loader>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

UserTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserTable);
