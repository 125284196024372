import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import SideMenu from "../navigation/Navigation.js";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Menu from "@material-ui/core/Menu/Menu";
import { connect } from "react-redux";
import styles from "./HeaderStyles";
import { logout } from "../../Axios";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const Header = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const history = useHistory();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  const handleChange = e => {
    setSearch(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    history.replace("/search/" + encodeURI(search));
  };

  const { classes } = props;
  const open = Boolean(anchorEl);

  return (
    <div className="row">
      <div className={classes.root}>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <SideMenu />
            <Link to={"/"}>
              <Typography className={classes.title} variant="h6" color="textSecondary" noWrap>
                Intranet
              </Typography>
            </Link>
            <div className={classes.grow} />
            <div className={classes.search}>
              <form onSubmit={handleSubmit}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Input
                  placeholder="Search…"
                  disableUnderline
                  value={search}
                  onChange={handleChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
                <Input type={"submit"} hidden />
              </form>
            </div>
            <div>
              <IconButton
                aria-owns={open ? "Navigation-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                className={classes.menu}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                PaperProps={{
                  style: {
                    width: 200
                  }
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogOut}>Log out</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(Header));
