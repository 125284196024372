import TextField from "@material-ui/core/TextField";
import React, { FunctionComponent } from "react";
import PropTypes from "prop-types";
import styles from "./Styles";
import { withStyles } from "@material-ui/core";
import { capitalize } from "../../util/functions";

type Props = {
  field: string;
  placeholder?: string;
  value?: string;
  handleChange: Function;
  classes: any;
};

const TextComponent: FunctionComponent<Props> = ({ field, placeholder, value, handleChange, classes }) => {
  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: value ? true : false }}
      id={field}
      label={placeholder ?? capitalize(field.split("_").join(" "))}
      className={classes.input}
      variant="outlined"
      value={value}
      onChange={e => handleChange(e.target.id, e.target.value)}
    />
  );
};

TextComponent.propTypes = {
  field: PropTypes.string.isRequired
};

export default withStyles(styles)(TextComponent);
