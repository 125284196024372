import { combineReducers } from "redux";
import {actions} from "./actions";


const user = (state = null, action) => {
    switch (action.type) {
        case actions.SET_USER :
            return action.payload;
        case actions.CLEAR_USER :
            return null;
        default :
            return state;
    }
};

const errors = (state=[], action) => {
    switch (action.type) {
        case actions.ADD_ERROR :
            return [
                ...state,
                action.payload
            ];
        case actions.CLEAR_ERROR :
            return state.filter((message, index) => index !== action.payload);
        default:
            return state;
    }
};

const messages = (state=[], action) => {
    switch (action.type) {
        case actions.ADD_MESSAGE :
            return [
                ...state,
                action.payload
            ];
        case actions.CLEAR_MESSAGE :
            return state.filter((message, index) => index !== action.payload);
        default:
            return state;
    }
};

const savedProjects = (state=[], action) => {
    switch (action.type) {
        case actions.ADD_SAVED :
            if(state.includes(action.payload)) {
                return state
            } else {
                return [
                    ...state,
                    action.payload
                ];
            }
        case actions.CLEAR_SAVED :
            return state.filter((saved) => saved !== action.payload);
        case actions.CLEAR_ALL_SAVED :
            return [];
        default:
            return state
    }
};

export default combineReducers({errors, messages, savedProjects, user})