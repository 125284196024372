import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  withStyles
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useEffect, useReducer } from "react";
import { FunctionComponent, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import IUser from "../../interfaces/IUser";
import { updateUser } from "../../Axios";

const styles = theme => ({
  divider: {
    marginBottom: theme.spacing(1.5)
  },
  input: {
    display: "flex",
    flexBasis: 250,
    flexGrow: 1,
    minHeight: 70
  }
});

type Props = {
  classes: any;
  user: IUser;
  handleUpdate: Function;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUser: FunctionComponent<Props> = props => {
  const { classes, handleUpdate } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState(props.user);
  const handleSave = () => {
    updateUser(user, () => {
      handleUpdate(user);
      setOpen(false);
    });
  };

  useEffect(() => {
    open === false && user;
  }, [open]);
  return (
    <div>
      <IconButton onClick={() => setOpen(true)} aria-label="edit">
        <EditIcon fontSize="default" />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-slide-title">{"Edit User"}</DialogTitle>
        <DialogContent>
          <TextField
            name={"first_name"}
            label="First name"
            className={classes.input}
            fullWidth
            variant="outlined"
            value={user ? user.first_name : ""}
            onChange={event => setUser({ ...(user && user), [event.target.name]: event.target.value })}
          />
          <TextField
            name={"last_name"}
            label="Last name"
            className={classes.input}
            fullWidth
            variant="outlined"
            value={user ? user.last_name : ""}
            onChange={event => setUser({ ...(user && user), [event.target.name]: event.target.value })}
          />
          <TextField
            name={"email"}
            label="Email"
            className={classes.input}
            fullWidth
            variant="outlined"
            value={user ? user.email : ""}
            onChange={event => setUser({ ...(user && user), [event.target.name]: event.target.value })}
          />

          <TextField
            name={"password"}
            label="New Password"
            className={classes.input}
            fullWidth
            autoComplete={"off"}
            variant="outlined"
            onChange={event => setUser({ ...(user && user), [event.target.name]: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(EditUser);
