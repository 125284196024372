import React, { useState, useEffect } from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core";
import IOpportunity from "../../interfaces/IOpportunity";
import INews from "../../interfaces/INews";
import { searchOpportunities, searchNews } from "../../Axios";
import { useParams } from "react-router-dom";
import OpportunitiesTable from "../../components/OpportunitiesTable";
import NewsTable from "../../components/NewsTable";
import FAButton from "../../components/fabButton/FAButton";

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: "100%",
      flexGrow: 1,
      margin: spacing(2)
    },
    table: {
      minWidth: 500
    },
    tableWrapper: {
      overflowX: "auto"
    },
    row: {
      display: "contents",
      "&:hover": {
        background: palette.primary.main
      }
    },
    flex: {
      display: "flex"
    },
    title: {
      alignSelf: "center",
      display: "initial",
      marginRight: 10
    },
    header: {
      flex: 1,
      paddingTop: spacing(2)
    },
    label: {
      marginBottom: 0
    },
    button: {
      marginTop: spacing(3),
      marginBottom: spacing(3),
      marginRight: "auto",
      marginLeft: "auto"
    }
  });

const Search = () => {
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [news, setNews] = useState<INews[]>([]);
  const [opportunitiesLoading, setOpportunitiesLoading] = useState<boolean>(true);
  const [newsLoading, setNewsLoading] = useState<boolean>(true);
  const { param } = useParams<{ param: string }>();

  useEffect(() => {
    searchOpportunities(param, opportunities => {
      setOpportunities(opportunities);
      setOpportunitiesLoading(false);
    });

    searchNews(param, news => {
      setNews(news);
      setNewsLoading(false);
    });
  }, [param]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12 opportunity-container">
          <OpportunitiesTable opportunities={opportunities} loading={opportunitiesLoading} search={param} />
        </div>
        <div className="col-md-12 opportunity-container">
          <NewsTable news={news} loading={newsLoading} search={param} />
        </div>
      </div>
      <FAButton />
    </div>
  );
};

export default withStyles(styles)(Search);
