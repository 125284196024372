import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import "./assets/main.css";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Dashboard from "./pages/Dashboard";
import Header from "./components/header/Header";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import SingleNews from "./pages/News/SingleNews";
import Opportunities from "./pages/Opportunities";
import Home from "./pages/Home";
import Login from "./pages/Login";
import OpportunityForm from "./pages/Opportunities/OpportunityForm";
import Opportunity from "./pages/Opportunities/Opportunity";
import NewsForm from "./pages/News/NewsForm";
import News from "./pages/News";
import NotFound from "./pages/NotFound";
import Notifications from "./components/Notifications";
import theme from "./assets/theme";
import PrivateRoute from "./components/PrivateRoute";

const App = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/">
            <div>
              <Header />
              <Home />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/opportunities">
            <div>
              <Header />
              <Opportunities />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/opportunities/create">
            <div>
              <Header />
              <OpportunityForm />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/opportunities/edit/:id">
            <div>
              <Header />
              <OpportunityForm edit={true} />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/opportunities/:id">
            <div>
              <Header />
              <Opportunity />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/news/create">
            <div>
              <Header />
              <NewsForm />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/news/edit/:id">
            <div>
              <Header />
              <NewsForm edit={true} />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/news/:id">
            <div>
              <Header />
              <SingleNews />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/news">
            <div>
              <Header />
              <News />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/profile">
            <div>
              <Header />
              <Profile />
            </div>
          </PrivateRoute>

          <PrivateRoute path="/search/:param">
            <div>
              <Header />
              <Search />
            </div>
          </PrivateRoute>

          <PrivateRoute exact path="/search">
            <div>
              <Header />
              You did not search for anything, try again
            </div>
          </PrivateRoute>

          <PrivateRoute user={props.user} path="/admin">
            <div>
              <Header />
              <Dashboard {...props} />
            </div>
          </PrivateRoute>

          <Route exact path="/login">
            <div>
              <Login {...props} />
            </div>
          </Route>

          <Route component={NotFound} />
        </Switch>
        <Notifications />
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
