import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import { withStyles, Button, Divider, Typography, TextField, Paper } from "@material-ui/core";
import styles from "./NewsFormStyles";
import PropTypes from "prop-types";
import { createNews, getSingleNews, updateSingleNews } from "../../Axios";
import fields from "./FormFields";
import BackIcon from "@material-ui/icons/ArrowBack";
import { Link, useHistory, useParams } from "react-router-dom";
import NumberComponent from "../../components/input/Number";
import SelectComponent from "../../components/input/Select";
import Editor from "../../components/Editor";
import TextComponent from "../../components/input/Text";
import DateComponent from "../../components/input/Date";
import Loader from "../../components/Loader";
import FileUpload from "../../components/fileUpload/FileUpload";
import INews from "../../interfaces/INews";

type Props = {
  edit?: boolean;
  classes: any;
};

const NewsForm: FunctionComponent<Props> = props => {
  const [news, setNews] = useState<INews>({});
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const { classes, edit } = props;

  useEffect(() => {
    edit
      ? getSingleNews(id)
          .then(news => {
            setNews(news);
            setLoading(false);
          })
          .catch(() => setLoading(false))
      : setLoading(false);
  }, []);

  const handleChange = useCallback(
    (property: string, value: any) => {
      setNews({ ...news, [property]: value });
    },
    [news]
  );

  const handleSave = () => {
    setLoading(true);
    const files = news.filepond?.map(fileItem => fileItem.file.name);
    createNews({
      ...news,
      files: files
    })
      .then((news: any) => history.push("/news/" + news._id))
      .catch(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    const files = news.filepond?.map(fileItem => fileItem.file.name);
    updateSingleNews({
      ...news,
      files: files
    })
      .then((news: any) => history.push("/news/" + news._id))
      .catch(() => setLoading(false));
  };

  const ComponentSwitch = field => {
    switch (field.type) {
      case "select":
        return (
          <SelectComponent
            key={field.name}
            field={field.name}
            filters={filters}
            handleChange={handleChange}
            value={news?.[field.name]}
            isClearable={field.clearable}
            isMulti={field.multi}
          />
        );
      case "editor":
        return <Editor key={field.name} field={field.name} value={news?.[field.name]} handleChange={handleChange} />;
      case "number":
        return (
          <NumberComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            format={field.format && field.format}
            value={news?.[field.name]}
          />
        );
      case "date":
        return (
          <DateComponent key={field.name} field={field.name} handleChange={handleChange} value={news?.[field.name]} />
        );
      case "text":
        return (
          <TextComponent key={field.name} field={field.name} handleChange={handleChange} value={news?.[field.name]} />
        );
    }
  };

  return (
    <Loader loading={loading}>
      <Paper className={classes.paper} elevation={10}>
        <div className={"container"}>
          <div>
            {edit && (
              <Link to={"/news/" + news._id}>
                <Button
                  startIcon={<BackIcon />}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  className={classes.margin}
                >
                  Back to News
                </Button>
              </Link>
            )}
            <div className={classes.row}>
              <Typography variant="h4" className={classes.title}>
                {edit ? "Edit" : "Create"} News
              </Typography>
            </div>
            <Divider className={classes.divider} />

            <div className={classes.row}>
              <TextField
                id="title"
                label="Title*"
                className={classes.input}
                variant="outlined"
                value={news.title ?? ""}
                onChange={e => handleChange(e.target.id, e.target.value)}
                fullWidth
              />
            </div>

            <div className={classes.row}>
              {fields.filter(field => field.category === "textarea").map(field => ComponentSwitch(field))}
            </div>

            <div style={{ margin: "24px 0px 24px 0px" }}>
              <FileUpload id={news._id} data={news.files} handleUpdate={handleChange} />
            </div>
          </div>
          <div style={{ justifyContent: "center" }}>
            <Button
              fullWidth
              color={"primary"}
              variant={"contained"}
              onClick={edit ? () => handleUpdate() : () => handleSave()}
            >
              Save
            </Button>
          </div>

          <div className={classes.bottomSpace} />
        </div>
      </Paper>
    </Loader>
  );
};

NewsForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewsForm);
