import React, { useState } from 'react';
import { withStyles, Card, CardContent, Button, Input, Typography, AppBar, Toolbar, CardHeader, FormControl } from '@material-ui/core';
import { findGetParameter } from "../../util/functions";
import { login } from "../../Axios";
import styles from "./LoginStyles"
import { useHistory } from 'react-router-dom';


const Login = (props) => {
  const [user, setUser] = useState<{ email: string, password: string }>({ email: "", password: "" })
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()


  const handleChange = prop => event => {
    setUser({ ...user, [prop]: event.target.value })
    setError(null)
  };

  const handleLogin = event => {
    event.preventDefault();
    setLoading(true)

    login(user)
      .then(() =>
        findGetParameter("redirect") ? history.push(findGetParameter("redirect")) : history.push("/")
      )
      .catch(() => setLoading(false))
  }

  const { classes } = props;

  return (
    <div className={classes.container}>
      <AppBar position="static" className={classes.header} color="primary">
        <Toolbar>
          <Typography variant="h6" color="inherit">
            Intranet
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.form}>
        {error && (
          <Card className={classes.errorBox}>
            <CardHeader
              disableTypography
              title={
                <Typography variant="body1" className={classes.title}>
                  {error}
                </Typography>
              }
            />
          </Card>
        )}

        <Card className={classes.root} color={"primary"}>
          <CardHeader
            disableTypography
            className={classes.cardHeader}
            title={
              <Typography variant="h5" className={classes.title}>
                Login
                </Typography>
            }
          />
          <CardContent>
            <form>
              <div>
                <FormControl className={classes.field}>
                  <Input
                    disabled={loading}
                    placeholder="Email"
                    className={classes.input}
                    value={user.email}
                    autoComplete={"email"}
                    onChange={handleChange("email")}
                    inputProps={{
                      'aria-label': 'Email',
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.field}>
                  <Input
                    disabled={loading}
                    placeholder="Password"
                    className={classes.input}
                    type="password"
                    name={"password"}
                    autoComplete={"password"}
                    value={user.password}
                    onChange={handleChange('password')}
                    inputProps={{
                      'aria-label': 'Password',
                    }}
                  />
                </FormControl>
              </div>

              <Button disabled={loading} type={"submit"} className={classes.button} size={"large"} color={"primary"}
                variant={"contained"} onClick={handleLogin}>Login</Button>
            </form>
          </CardContent>

        </Card>
      </div>

    </div>
  )
}

export default withStyles(styles)(Login)