const styles = theme => ({
  root: {
    background: "#fff"
  },
  card: {
    //background: Indigo[100],
    "& a": {
      color: "inherit",

      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  info: {
    paddingLeft: 10
    //color: Indigo[700]
  },
  cardActionArea: {
    width: "100%"
  },
  actionArea: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    background: "inherit"
  },
  newOpportunityAvatar: {
    backgroundColor: theme.palette.tertiary.main
  },
  inProgressAvatar: {
    background: theme.palette.primary.main
  },

  comments: {
    fontWeight: 700
  },
  commentIcon: {
    marginRight: 16,
    paddingTop: 16
  },
  newOpportunityBadgeRoot: {
    background: theme.palette.common.white,
    color: theme.palette.tertiary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.tertiary.main
  },
  inProgressBadgeRoot: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main
  },

  inProgressBadge: {
    color: theme.palette.tertiary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.tertiary.main,
    backgroundColor: theme.palette.common.white
  },
  greenIcon: {
    color: theme.palette.tertiary.main
  },
  blueIcon: {
    color: theme.palette.primary.main
  }
});

export default styles;
