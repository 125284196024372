import {createMuiTheme} from '@material-ui/core/styles/index';

import Pink from '@material-ui/core/colors/pink'
import Blue from '@material-ui/core/colors/blue';
import Indigo from '@material-ui/core/colors/indigo';
import Orange from '@material-ui/core/colors/orange';
import Purple from '@material-ui/core/colors/purple';
import DeepOrange from '@material-ui/core/colors/deepOrange';
import Teal from '@material-ui/core/colors/teal';
import {green, orange, red, yellow} from "@material-ui/core/colors";



export default createMuiTheme({
    palette: {
        primary: {
            main: Blue[700],
            contrastText: '#fff'
        },
        secondary: {
            main: DeepOrange[700]
        },
        tertiary: {
            light: Teal[500],
            main: Teal[700],
            dark: Teal[900]
        },
        status: {
            won: green[700],
            lost: red[700],
            dropped: orange[700],
            inprogress: yellow[700]
        }
    },
    textField: {
        focusColor: "#fff"
    },
    typography: {

    }
});

