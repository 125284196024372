const styles = theme => ({
    avatar: {
        marginTop: 10,
        background: theme.palette.primary.main
    },
    comment: {},
    subcomment: {},

    rightAlignment: {
        // paddingRight: 0
    },
    leftAlignment: {
        // paddingLeft: 0
    },
    replybutton: {},
    iconbutton: {
        transform: 'rotate(-45deg)'
    },
    commentTitle: {
        fontWeight: "bold",
        color: theme.palette.primary.main
    },
    commentBody: {
        whiteSpace: 'pre-wrap',
        lineHeight: 1.5,
    },
    date: {
        float: 'right'
    },
    commentFlex: {
        display: 'flex',
    },
    commentBox: {
        margin: 0,
        flexGrow: 1,
        alignItems: 'flex-start'
    },
    cardContent: {
        padding: 12,
        "&:last-child": {
            paddingBottom: 14
        }
    }
});

export default styles