import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import Paper from '@material-ui/core/Paper/index';
import {profileStyles} from "../assets/styles";
import {Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar/index";
import PersonIcon from '@material-ui/icons/Person';
import TextField from "@material-ui/core/TextField/index";
import {getCurrentUser} from "../Axios";
import Loader from "../components/Loader";


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: true
        }
    }

    componentDidMount() {
        getCurrentUser(user => this.setState({user, loading: false}))
    }

    render() {
        const {classes} = this.props;
        const {user, loading} = this.state;


        return (<Loader loading={loading}>

                <div className={classes.container}>
                    <Typography variant={"h4"} className={classes.warning}>
                        Editing is currently disabled
                    </Typography>
                    <Paper className={classes.profile}>

                        <Paper className={classes.header}>
                            <Avatar className={classes.avatar}>
                                <PersonIcon fontSize={"large"}/>
                            </Avatar>
                            <Typography variant={"h4"} className={classes.title}>
                                {user && user.first_name + ' ' + user.last_name}
                            </Typography>
                        </Paper>
                        <div className={classes.content}>
                            <TextField
                                label="First Name"
                                className={classes.input}
                                variant="outlined"
                                disabled={true}
                                value={user && user.first_name}
                                //onChange={this.handleChange}
                                fullWidth
                            />
                        <TextField
                                label="Last Name"
                                className={classes.input}
                                variant="outlined"
                                disabled={true}
                                value={user && user.last_name}
                                //onChange={this.handleChange}
                                fullWidth
                            />
                        <TextField
                                label="Email"
                                className={classes.input}
                                variant="outlined"
                                disabled={true}
                                value={user && user.email}
                                //onChange={this.handleChange}
                                fullWidth
                            />
                        </div>
                    </Paper>
                </div>
            </Loader>

        )
    }
}

export default (withStyles(profileStyles)(Profile));
