import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const PrivateRoute = props => {
  return (
    <Route {...props}>
      {props.user ? (
        props.children
      ) : (
        <Redirect to={"/login" + (window.location.pathname.length && "?redirect=" + window.location.pathname)} />
      )}
    </Route>
  );
};
export default connect(mapStateToProps)(PrivateRoute);
