import CreatableSelect from "react-select/creatable";
import React, { FunctionComponent } from "react";
import styles from "./Styles";
import { withStyles } from "@material-ui/core";
import { capitalize } from "../../util/functions";

const selectStyles = {
  control: styles => ({
    ...styles,
    minHeight: 56,
    borderColor: "rgba(0, 0, 0, 0.23)",
    "&:hover": {
      borderColor: "inherit"
    }
  }),
  menu: styles => ({ ...styles, zIndex: 9999 })
};

type Props = {
  field: string;
  placeholder?: string;
  value?: string | string[];
  isMulti?: boolean;
  isClearable?: boolean;
  handleChange: Function;
  filters: any;
  classes: any;
};

const SelectComponent: FunctionComponent<Props> = ({
  field,
  placeholder,
  value,
  isMulti = false,
  isClearable = true,
  handleChange,
  filters,
  classes
}) => {
  return (
    <CreatableSelect
      value={
        value
          ? value instanceof Array
            ? value.map(val => ({
                value: val,
                label: val
              }))
            : { value: value, label: value }
          : null
      }
      placeholder={placeholder ?? capitalize(field.replace(/_/g, " "))}
      className={classes.input}
      options={filters?.[field]?.filter(filter => filter).sort((a, b) => (a.value > b.value ? 1 : -1))}
      styles={selectStyles}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={(newValue, actionMeta) => {
        const values = newValue ? (Array.isArray(newValue) ? newValue.map(a => a.value) : newValue.value) : null;
        handleChange(field, values);
      }}
    />
  );
};

export default withStyles(styles)(SelectComponent);
