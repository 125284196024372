import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {getOpportunities} from "../../Axios";
import OpportunitiesTable from "../../components/OpportunitiesTable";
import FAButton from "../../components/fabButton/FAButton";


const styles = theme => ({
    root: {
        width: '100%',
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    table: {
        minWidth: 500,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        display: 'contents',
        '&:hover': {
            background: theme.palette.primary.main
        }
    },
    flex: {
        display: 'flex'
    },
    title: {
        alignSelf: 'center',
        display: 'initial',
        marginRight: 10
    },
    header: {
        flex: 1,
        paddingTop: theme.spacing(2)
    },
    label: {
        marginBottom: 0
    },
    button: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginRight: 'auto',
        marginLeft: 'auto'
    }

});

class Opportunities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunities: [],
            page: 0,
            opportunitiesPerPage: 10,
            list: true,
            size: 9,
            loaded: false
        };

        this.handleSwitch = this.handleSwitch.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState({
            size: this.state.size + 9
        })
    }

    handleSwitch() {
        this.setState({
            list: !this.state.list
        })
    }

    componentDidMount() {
        getOpportunities(opportunities => {
            this.setState({
                opportunities: opportunities,
                loaded: true
            })

        })
    }


    render() {
        const {opportunities, loaded} = this.state;


        return (
            <div>
                    <div className="row">
                        <div className="col-md-12 opportunity-container">

                            <OpportunitiesTable opportunities={opportunities} loaded={loaded} name={"myname"}/>

                        </div>
                    </div>
                    <FAButton/>
            </div>
        )
    }
};


export default withStyles(styles)(Opportunities);
