import Blue from "@material-ui/core/colors/blue";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles, Theme } from "@material-ui/core";

export const styles = ({ palette, spacing, breakpoints, shape, transitions }: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    header: {
      background: Blue[700],
      color: palette.common.white
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    menu: {
      marginTop: 40
    },
    title: {
      color: palette.common.white,
      display: "none",
      [breakpoints.up("sm")]: {
        display: "block"
      },
      transition: "300ms",
      "&:hover": {
        transition: "300ms",
        opacity: "0.7"
      }
    },
    search: {
      position: "relative",
      borderRadius: shape.borderRadius,
      backgroundColor: fade(palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(palette.common.white, 0.25)
      },
      marginLeft: 0,
      width: "100%",
      [breakpoints.up("sm")]: {
        marginLeft: spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      padding: spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${spacing(4)}px)`,
      transition: transitions.create("width"),
      width: "100%",
      [breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch"
        }
      }
    }
  });

export default styles;
