export const actions = {
    ADD_ERROR: "ADD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",

    ADD_MESSAGE: "ADD_MESSAGE",
    CLEAR_MESSAGE: "CLEAR_MESSAGE",

    ADD_SAVED: "ADD_SAVED",
    CLEAR_SAVED: "CLEAR_SAVED",
    CLEAR_ALL_SAVED: "CLEAR_ALL_SAVED",

    SET_USER: "SET_USER",
    CLEAR_USER: "CLEAR_USER"
};

export const variants = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR"
};

export const setUser = (user) => ({
    type: actions.SET_USER,
    payload: user
});

export const clearUser = () => ({
    type: actions.CLEAR_USER,
});

export const addError = (message) =>
    ({
        type: actions.ADD_ERROR,
        payload: message
    });

export const clearError = (index) =>
    ({
        type: actions.CLEAR_ERROR,
        payload: index
    });


export const addMessage = (message, variant) =>
    ({
        type: actions.ADD_MESSAGE,
        payload: {
            message: message,
            variant: variant
        }
    });

export const clearMessage = (index) =>
    ({
        type: actions.CLEAR_MESSAGE,
        payload: index
    });

export const addSavedProject = (id) =>
    ({
        type: actions.ADD_SAVED,
        payload: id
    });

export const clearSavedProject = (id) =>
    ({
        type: actions.CLEAR_SAVED,
        payload: id
    });

export const clearAllSavedProject = () =>
    ({
        type: actions.CLEAR_ALL_SAVED,
    });
